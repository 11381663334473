<template>
    <div class="task_sidebar_wrapper">
        <template v-if="task">
            <div 
                v-if="asideSetting" 
                class="task_sidebar sidebar_aside aside_item">
                <template v-if="!isMobile && asideSetting.showStatus">
                    <div class="sidebar_item">
                        <TaskStatus :status="task.status" />
                    </div>
                </template>

                <div 
                    v-if="asideSetting.showCreated" 
                    class="sidebar_item">
                    <div class="mb-1 text-sm font-semibold">
                        {{$t('task.created')}}
                    </div>
                    <div>{{$moment(task.created_at).format('DD.MM.YYYY в HH:mm')}}</div>
                </div>
                <div 
                    v-if="asideSetting.date_start_plan && task.date_start_plan" 
                    class="sidebar_item">
                    <div class="mb-1 text-sm font-semibold">
                        {{ asideSetting.date_start_plan.label }}
                    </div>
                    <div>{{$moment(task.date_start_plan).format('DD.MM.YYYY в HH:mm')}}</div>
                </div>

                <div 
                    v-if="task.date_start_fact" 
                    class="sidebar_item">
                    <div class="mb-1 text-sm font-semibold">
                        {{$t('task.date_start_fact')}}
                    </div>
                    <div>{{$moment(task.date_start_fact).format('DD.MM.YYYY в HH:mm')}}</div>
                </div>
                
                <template v-if="!isMobile">
                    <div 
                        v-if="asideSetting.showDeadline" 
                        class="sidebar_item">
                        <div class="mb-1 text-sm font-semibold">
                            {{$t('task.dead_line')}}
                        </div>
                        <DeadLine 
                            :taskStatus="task.status" 
                            :date="task.dead_line" />
                    </div>
                    <div 
                        v-if="asideSetting.showPriority" 
                        class="sidebar_item">
                        <div class="mb-1 text-sm font-semibold">
                            {{$t('task.priority')}}
                        </div>
                        <div class="flex items-center">
                            <a-badge :status="priorityCheck.color" />
                            {{priorityCheck.name}}
                        </div>
                    </div>
                </template>
                <div 
                    class="sidebar_item" 
                    v-if="task.sprint || task.sprint_history.length > 0">
                    <div class="mb-1 text-sm font-semibold">
                        {{$t('task.participant_sprint')}}
                    </div>
                    <a 
                        v-if="task.sprint" 
                        @click="openSprint(task.sprint.id)" 
                        class="flex items-center">
                        {{task.sprint.name }}
                    </a>
                    <div v-else-if="task.sprint_history.length > 0">
                        <a 
                            v-for="item in task.sprint_history" 
                            :key="item.id" 
                            @click="openSprint(item.id)" 
                            class="flex items-center">
                            {{item.name}}
    
                        </a>
                    </div>
                </div>
                <div 
                    v-if="task.contractor"
                    class="sidebar_item">
                    <div class="mb-1 text-sm font-semibold">
                        {{$t('task.contractor')}}
                    </div>
                    <span class="flex items-center">
                        {{task.contractor.name }}
                    </span>
                    <div v-if="task.contact_person" class="mt-1">
                        <Profiler 
                            :user="task.contact_person" 
                            initStatus
                            :subtitle="{ text: $t('task.contact_user') }" />
                    </div>            
                </div>
                <div 
                    v-if="task.potential_contractor" 
                    class="sidebar_item">
                    <div class="mb-1 text-sm font-semibold">
                        {{$t('task.potential_contractor')}}
                    </div>
                    <span class="flex items-center">
                        {{task.potential_contractor.name}}
                    </span>
                </div>
                <div 
                    v-if="task.potential_contractor && task.potential_contractor.company_name" 
                    class="sidebar_item">
                    <div class="mb-1 text-sm font-semibold">
                        {{$t('task.potential_contractor_company_name')}}
                    </div>
                    <span class="flex items-center">
                        {{task.potential_contractor.company_name}}
                    </span>
                </div>
                <div 
                    v-if="task.potential_contractor && task.potential_contractor.business_region_name" 
                    class="sidebar_item">
                    <div class="mb-1 text-sm font-semibold">
                        {{$t('task.potential_contractor_region_name')}}
                    </div>
                    <span class="flex items-center">
                        {{task.potential_contractor.business_region_name}}
                    </span>
                </div>
                <div 
                    v-if="task.potential_contractor && task.potential_contractor.phone" 
                    class="sidebar_item">
                    <div class="mb-1 text-sm font-semibold">
                        {{$t('task.task_phone')}}
                    </div>
                    <span class="flex items-center">
                        <a :href="'tel:' + task.potential_contractor.phone">{{ task.potential_contractor.phone }}</a>
                    </span>
                </div>
                <div 
                    v-if="task.potential_contractor && task.potential_contractor.email" 
                    class="sidebar_item">
                    <div class="mb-1 text-sm font-semibold">
                        {{$t('task.email')}}
                    </div>
                    <span class="flex items-center">
                        <a :href="'mailto:' + task.potential_contractor.email">{{ task.potential_contractor.email }}</a>
                    </span>
                </div>
                <div 
                    v-if="asideSetting.owner" 
                    class="sidebar_item">
                    <div>
                        <Profiler 
                            :user="task.owner" 
                            initStatus
                            :subtitle="{text: asideSetting.owner ? asideSetting.owner.label : $t('task.owner')}" />
                    </div>
                </div>
                <div 
                    v-if="asideSetting.operator" 
                    class="sidebar_item">
                    <div>
                        <template v-if="user && task.is_auction">
                            <div class="text-gray mb-1">
                                {{ asideSetting.operator ? asideSetting.operator.label : $t('task.operator') }}
                            </div>
                            <a-popconfirm
                                :title="$t('task.handler.confirmTakeTask')"
                                :ok-text="$t('task.yes')"
                                :cancel-text="$t('task.no')"
                                @confirm="takeTask(task)">
                                <a-button
                                    type="primary"
                                    ghost
                                    class="flex items-center"
                                    :loading="takeLoader">
                                    <i class="fi fi-rr-user-add mr-2"></i>
                                    {{ $t('task.handler.ok') }}
                                </a-button>
                            </a-popconfirm>
                        </template>
                        <Profiler
                            v-else 
                            :user="task.operator" 
                            initStatus
                            :subtitle="{
                                text: asideSetting.operator ? asideSetting.operator.label : $t('task.operator')
                            }" />
                    </div>
                </div>
                <div 
                    v-if="asideSetting.visors && task.visors.length" 
                    class="sidebar_item">
                    <div 
                        v-for="user in task.visors" 
                        :key="user.id" 
                        class="visor_item">
                        <Profiler 
                            :user="user"
                            initStatus
                            :subtitle="{
                                text: asideSetting.visors ? asideSetting.visors.label : $t('task.visor')
                            }" />
                    </div>
                </div>
                <div 
                    v-if="asideSetting.organization && task.organization" 
                    class="sidebar_item">
                    <div class="mb-1 text-sm font-semibold">
                        {{$t('task.organization')}}
                    </div>
                    <div 
                        class="flex items-center cursor-pointer" >
                        <!-- @click="openWorkgroup('viewGroup', task.organization)" -->
                        <div>
                            <a-avatar 
                                :src="task.organization.logo" 
                                icon="team" 
                                :size="32" />
                        </div>
                        <span class="ml-2">{{task.organization.name}}</span>
                    </div>
                </div>
                <div 
                    v-if="task.project && asideSetting.project" 
                    class="sidebar_item">
                    <div class="mb-1 text-sm font-semibold">
                        {{$t('task.project')}}
                    </div>
                    <div 
                        class="flex items-center cursor-pointer" 
                        @click="openWorkgroup('viewGroup', task.project)">
                        <div>
                            <a-avatar 
                                :src="workgroupLogoPath(task.project)" 
                                icon="team" 
                                :size="32" />
                        </div>
                        <span class="ml-2">{{task.project.name}}</span>
                    </div>
                </div>
                <div 
                    v-if="task.workgroup && asideSetting.workgroup" 
                    class="sidebar_item">
                    <div class="mb-1 text-sm font-semibold">
                        {{$t('task.workgroup')}}
                    </div>
                    <div 
                        class="flex items-center cursor-pointer" 
                        @click="openWorkgroup('viewProject', task.workgroup)">
                        <div>
                            <a-avatar 
                                :src="workgroupLogoPath(task.workgroup)" 
                                icon="team" 
                                :size="32" />
                        </div>
                        <span class="ml-2">{{task.workgroup.name}}</span>
                    </div>
                </div>
            </div>
            
            <StatSwitch 
                v-for="stat in asideStat" 
                :key="stat.key"
                class="aside_item"
                :task="task"
                :stat="stat" />
        </template>
        <template v-else>
            <a-skeleton 
                active 
                avatar 
                :paragraph="{ rows: 4 }" />
            <a-skeleton 
                active 
                avatar 
                :paragraph="{ rows: 4 }" />
        </template>
    </div>
</template>

<script>
import DeadLine from '../DeadLine'
import TaskStatus from '../TaskStatus'
import StatSwitch from './StatWidgets/StatSwitch.vue'
import taskHandler from '../mixins/taskHandler.js'
import {priorityList} from '../../utils'
export default {
    mixins: [
        taskHandler
    ],
    components: {
        DeadLine,
        TaskStatus,
        StatSwitch
    },
    props: {
        task: {
            type: Object,
            default: () => null
        },
        closeDrawer: {
            type: Function,
            default: () => {}
        },
        isMobile: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            priorityList,
            loading: false
        }
    },
    computed: {
        asideSetting() {
            return this.task.aside_settings ? this.task.aside_settings : null 
        },
        priorityCheck() {
            const find = this.priorityList.find(item => item.value === this.task.priority)
            if(find)
                return find
            else
                return null
        },
        asideStat() {
            if(this.task.statWidgets?.length)
                return this.task.statWidgets
            else
                return []
        },
        user() {
            return this.$store.state.user.user
        }
    },
    methods: {
        openWorkgroup(type, item) {
            this.closeDrawer()
            this.$router.replace({
                query: { [type]: item.id }
            })
        },
        workgroupLogoPath(workgroup) {
            return workgroup?.workgroup_logo?.path || ''
        },
    }
}
</script>

<style lang="scss" scoped>
.aside_item{
    &:not(:last-child){
        margin-bottom: 15px;
    }
    .text-gray{
        color: var(--gray);
        font-size: 12px;
    }
}
</style>